html, body {
  background-color: #fff0ff;
  height: 100%;
  margin: 0;
}

.ROOT-CSS {
  height: 600;
  width: 400;
}

body::backdrop {
  background-color: #fff0ff;
}

.title {
  font-size: 700;
}

.half {
  width: 50%;
}

.header-tile {
  height: 10px;
}

.tile {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.section {
  padding: 0;
}

.categories {
  margin-top: 3%;
}

.to-bottom {
  flex: 1;
}

.tile.is-ancestor {
  margin-top: 15px;
  margin-right: 0;
  margin-left: 0;
}

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 10px;
}

.character-container {
  margin-bottom: 10px;
}

/* < - - - colors - - - > */

/* game canvas */
.game-background {
  background-color: rgba(0, 0, 0, 0.679);
}

/* green color for tile if name is correct */
.name-correct {
  background-color: #aabe4d;
}

/* darker green color for tile if characteristic is correct */
.correct {
  background-color: #BDD358;
  color:#66a05d
}

/* yellow color for tile if characteristic is close */
.almost-correct {
  background-color: #E5E059;
}

/* color for the name tile if guess is wrong */
.wrong {
  background-color: #78586F27;
}

/* color for the characteristic tile if guess is wrong */
.characteristic-wrong {
  background-color: #78586F27;
}

/* color for the text in the game */
.game-font-color {
  color: #086788;
}

.really-green {
  color: #06b506;
}